<script setup lang="ts">
import DropdownMenu from './DropdownMenu.vue'
import Logo from './Logo.vue'
import { customSearch, doSearch, searchResults, searchTerm } from '@/services/search'
import { onMounted, ref, watch } from 'vue'

const props = defineProps<{
  showBackButton: boolean
}>()
const search = ref('')
const showBackButtonRef = ref(props.showBackButton ?? false)

const categoriesClass = ['fixed', 'shadow-xl', 'border-b-2']

const onBlur = () => {
  setTimeout(() => {
    document.querySelector('#nav-categories')?.classList.remove(...categoriesClass)
  }, 200)
}

const onFocus = () => {
  document.querySelector('.animated-search .placeholder')?.classList.remove('placeholder-animation')
  document.querySelector('.animated-search > .logo')?.classList.add('!opacity-0')
  document.querySelector('#nav-categories')?.classList.add(...categoriesClass)
}

const onSubmit = (event: any) => {
  event.preventDefault()
  event.stopPropagation()
  document.querySelector('#search')?.blur()
  searchTerm.set(search.value)
  execSearch()
}

let throttle: any
const execSearch = (ignoreUrlChange: boolean = false) => {
  clearTimeout(throttle)
  window.scrollTo(0, 0)

  const currentUrl = new URL(window.location.href)
  if (!searchTerm.get() || searchTerm.get() === '') {
    currentUrl.searchParams.delete('busca')
    document.querySelector('#search')?.focus()
    searchResults.set(undefined)
    showBackButtonRef.value = false
  } else {
    currentUrl.searchParams.set('busca', searchTerm.get() ?? '')
    searchResults.set(doSearch())
    showBackButtonRef.value = true
  }

  if (!ignoreUrlChange) {
    if (currentUrl.pathname !== '/') {
      currentUrl.pathname = '/'
      window.location.href = currentUrl.toString()
    } else {
      window.history.pushState({}, '', currentUrl.toString())
    }
  }
}

const clearSearch = () => {
  searchTerm.set('')
  search.value = ''
  execSearch()
}

const parseUrl = () => {
  const searchParams = new URLSearchParams(window.location.search)
  searchTerm.set(searchParams.get('busca') ?? '')
  search.value = searchTerm.get() ?? ''

  if (searchTerm.get() !== '') {
    setTimeout(() => {
      const logo = document.querySelector('.animated-search .logo')
      logo?.classList.add('!opacity-0')

      const placeholder = document.querySelector('.animated-search .placeholder')
      placeholder?.classList.remove('placeholder-animation')
      placeholder?.classList.add('!opacity-0')

      execSearch(true)
    }, 50)
  }
}

watch(search, (newValue) => {
  clearTimeout(throttle)
  if (newValue === '') {
    document.querySelector('.animated-search .placeholder')?.classList.remove('!opacity-0')
  } else {
    document.querySelector('.animated-search .placeholder')?.classList.add('!opacity-0')
  }

  throttle = setTimeout(() => {
    if (newValue !== searchTerm.get()) {
      searchTerm.set(newValue)
      execSearch()
    }
  }, 1000)

  window.addEventListener('close-side-menu', (event: any) => {
    if (event.detail.item) {
      clearSearch()
    }
  })
})

onMounted(() => {
  ;(<any>window)._customSearch = customSearch

  window.addEventListener('popstate', function () {
    parseUrl()
  })

  setTimeout(() => parseUrl(), 500)
})
</script>

<template>
  <div class="w-full md:pr-1">
    <form action="#busca" method="get" id="searchForm" @submit.prevent="onSubmit">
      <label for="search" class="sr-only"> Pesquisa por produtos </label>
      <div class="flex rounded-md shadow-sm relative">
        <div
          class="relative inline-flex items-center border border-gray-300 text-sm font-medium rounded-l-md text-gray-700 border-r-0 focus:outline-none"
        >
          <DropdownMenu
            client:load
            :show-back-button="showBackButtonRef"
            @back="clearSearch"
            :use-custom-callback="true"
          />
        </div>

        <div class="absolute animated-search z-0 h-full">
          <div class="logo mt-1 mx-auto absolute -ml-2">
            <Logo client:only="vue" />
          </div>
          <div
            class="placeholder placeholder-animation mt-2 mx-auto absolute w-full text-center truncate px-0.5 -ml-2"
          >
            Pesquisar produtos
          </div>
        </div>

        <input
          @focus="onFocus"
          @blur="onBlur"
          v-model="search"
          type="search"
          name="busca"
          id="search"
          class="z-10 focus-within:z-10 focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none sm:text-sm border-gray-300 bg-transparent border-l-0"
        />

        <button
          type="submit"
          id="searchButton"
          class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
        >
          <svg
            class="h-5 w-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
          <span class="hidden">Pesquisar</span>
        </button>
      </div>
    </form>
  </div>
</template>

<style>
.animated-search {
  width: calc(100% - 108px);
  left: 54px;
}

.logo {
  animation: fadeOut 0.5s;
  animation-delay: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  zoom: 0.8;
  left: 50%;
  transform: translate(-50%, 0%);
}

.placeholder {
  left: 50%;
  transform: translate(-50%, 0%);
}

.placeholder-animation {
  animation: fadeIn 0.5s;
  animation-delay: 3.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  opacity: 0;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
